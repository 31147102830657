<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">订货单</a-divider>
			<a-descriptions :column="3">
				<a-descriptions-item label="订货单ID">
					{{ detail.order_id||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="订货单类型">
					{{ detail.order_type_view||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="订货单状态">
					{{ detail.confirm_name }}
				</a-descriptions-item>
				<a-descriptions-item label="下单时间">
					{{ detail.create_time }}
				</a-descriptions-item>
				<a-descriptions-item label="订单总数">
					{{ detail.total_count||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="申报人">
					{{ detail.apply_name||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="运输方式">
					{{ detail.transport_type||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="区域名称">
					{{ detail.organization_name||'--'}}
				</a-descriptions-item>
                <a-descriptions-item label="支付方式">
                	{{detail.pay_type_view}}
                </a-descriptions-item>
                <a-descriptions-item label="收货人">
                	{{detail.goods_reveiver}}
                </a-descriptions-item>
                <a-descriptions-item label="收货人电话">
                	{{detail.seller_phone}}
                </a-descriptions-item>
                <a-descriptions-item label="经销商名称">
                	{{detail.seller_name}}
                </a-descriptions-item>
                <a-descriptions-item label="地址">
                	{{detail.receive_address}}
                </a-descriptions-item>
                <a-descriptions-item label="类型">
                	{{detail.type_name}}
                </a-descriptions-item>
				<!-- <a-descriptions-item label="签字信息">
					{{ detail.storage_name }}
				</a-descriptions-item> -->
			</a-descriptions>

			<a-divider orientation="left">产品明细</a-divider>
            <div style="margin-bottom: 20px;">
                <a-table
                    class="mt10"
                    :columns="columns"
                    :pagination="false"
                    :dataSource="detail.goods_data"
                >
                </a-table>
            </div>

            <a-divider orientation="left">出库单</a-divider>
            <div v-for="(item,index) in detail.outcome_data" class="outcome_box">
                <div class="outcome_info">出库单ID</div>
                <div :class="item.delivery_id>0?'outcome_info_blue':''" @click='to_send(item)'>{{item.outcome_id}}</div>
            </div>
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		get_order_detail
	} from '@/api/push.js'
    const columns = [
        {
            title: "货物名称",
            dataIndex: "goods_name",
        },
        {
            title: "货物总数量",
            dataIndex: "total_count",
        },
        {
            title: "重量（吨）",
            dataIndex: "goods_weight",
        },
        {
            title: "货物编号",
            dataIndex: "goods_id",
        },
        {
            title: "购货数量",
            dataIndex: "goods_count",
        },
    ];

	export default {
		components: {
		},
		data() {
			return {
                columns,
				detail: {},
			}
		},
		created() {
			this.get_info()
		},
		methods: {
			get_info() {
				get_order_detail({
					data: {
						id: this.$route.query.id,
					}
				}).then(res => {
					this.detail = res.data.list
				})
			},
            to_send(item) {
				if(item.delivery_id>0){
					this.$keep_route.remove('send');
					this.$router.push('../send/index?keyword='+item.outcome_id)
				}
            },
			
		}
	}
</script>

<style lang="less" scoped>

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.li_item {
		width: 100%;
		.title_item {
			width: 80px;
			text-align: left;
			display: inline-block;
			color: #000;
		}

		margin-bottom: 30px;
	}

	.arpproval_title {
		padding: 15px 0;
		text-align: center;
		background: rgba(245, 245, 245, 1);
		font-size: 14px;
		span {
			width: 9%;
			display: inline-block;
		}

		.department_appr {
			width: 18%;
		}
	}

	.arpproval_title1 {
		height: auto;
		width: 300px;
		background: none;
		border: 1px solid rgba(245, 245, 245, 1);
		.department_appr {
			width: 50%;
		}
	}

	.ant-descriptions-item-label {
		width: 100px;
	}

    .outcome_box {
        border:1px solid #fafafa;
        height:50px;
        width: 500px;
        display: flex;
        align-items: center;

        div:first-child{
            flex: 1;
        }
        div:last-child{
            flex:1;
            // color:#1890FF
        }
		.outcome_info_blue{
			color:#1890FF;
			cursor: pointer;
		}
        .outcome_info {
            margin-left:15px
        }
    }
    
</style>
